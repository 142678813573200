@charset "utf-8";
@import url("base.css");

/***************************************************************************
 *
 * COMMON STYLE
 *
 ***************************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;500;700&display=swap'); 
/* add fonts  */

#loading{
    position: fixed;
    background: #03030378;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loading img{
    width: 80px;
}
/* end add fonts  */
.hirakaku {
    /*font-family: HiraKakuProN-W3, Hiragino Kaku Gothic ProN, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ ProN W3, ヒラギノ角ゴ Pro W3, Hiragino Sans, Yu Gothic Medium, 游ゴシック Medium, YuGothic, 游ゴシック体, メイリオ, sans-serif;*/
}
.btn:focus, .btn.focus{
    box-shadow: none
}
.error_two{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.error_two .text-danger{
    width: 47.7%;
}
.text-danger{
    color: red;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Noto Sans JP';
}
.sans {
    font-family: 'Noto Sans JP', sans-serif;
}

.serif {
    font-family: 'Noto Serif JP', serif;
}

body {
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    font-family: 'Noto Sans JP', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body.fixed {
    overflow: hidden;
}

.inner {
    /* max-width: 1465px; */
    width: 100%;
    margin: 0 auto;
    /* padding: 0 30px; */
}


/*VALIDATE CONTACT*/

.error {
    display: block;
    font-size: 12px;
    color: red;
}


/* HEADER */

#header {
    min-width: 768px;
    width: 100%;
    padding: 0;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.14);
    position: relative;
}
#header.fixed{
    position: fixed;
    z-index: 999;
    top: 0;
}
#header .mainMenu.removeOverflow{
    overflow: visible;
}
#header .lineHeader{
    width: 100%;
    height: 6px;
    background-color: #FFD702;
}
#header .headerBar{
    padding: 5px 0;
    background: #FFFFFF;
}
.headerBar .inner {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.headerBar .logo {
    width: 180px;
}
.headerBar .logo:hover{
    opacity: 0.6;
}
/* .mainMenu {
    width: 43.3%;
    display: flex;
    justify-content: left;
    align-items: center;
} */

.mainMenu .menu {
    display: flex;
    width: 100%;
}

.mainMenu .menu > li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
}
.mainMenu .menu li.mr-0 {
    margin-right: 0;
}
.mainMenu .menu li.pc {
    display: inline-block;
}
.mainMenu .menu li.sp {
    display: none;
}

.mainMenu .menu li a {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #040000;
    padding: 7px 21px;
    border: 0;
    border-radius: 5px;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
    /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
}

.mainMenu.notLogged .menu li a {
    background: #FFD702;
}
.mainMenu.notLogged .menu li a:hover{
    background: #040000;
    color: #FFD702;
    opacity: 1;
}
.mainMenu.logged .menu li a {
    padding: 2px 0px 1px 2px;letter-spacing: 0px;
}
.mainMenu.logged .menu li a.hover:hover{
    opacity: 0.7;
}
.mainMenu.logged .menu li a img{
    width: 35px;
    margin-right: 8px;
}
.mainMenu.logged .menu li a .txt{
    display: inline-block;
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
}
.mainMenu.logged .menu li > ul.subMenu{
    display: none;
    position: absolute;
    top: 51px;
    right: -20px;
    width: 100%;
    background-color: #FFD702;
    padding: 16px 10px;
    min-width: 211px;
    box-shadow: -4px 2px 10px -4px rgba(0,0,0,0.3);
    z-index: 99;
}
.mainMenu.logged .menu li > ul.subMenu li {
    margin-bottom: 7px;
}
.mainMenu.logged .menu li > ul.subMenu li:last-child{
    margin-bottom: 0;
}
.mainMenu.logged .menu li > ul.subMenu li a{
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 11px;
    color: #040000;
    font-size: 16px;
    font-weight: bold;
    display: block;
    padding: 14px 10px 14px 25px;
}

.mainMenu.logged .menu li > ul.subMenu li a img {
    width: 35px;
    margin-right: 5px;
}

#main {
    margin: 40px 0 75px;
}
#main img{
    width: 100%;
}
/* CONTENT# */

#content {
    width: 100%;
    background: #FFFFFF;
    flex: 1;
    /* background: pink; */
}
#content .areaAdminDesign {
    display: flex;
    width: 100%;
}
#content .areaDesign{
    display: flex;
    width: 100%;
}

#content .areaDesign .content {
    width: calc(100% - (150px + 335px)); 
    background: #FFFFFF; 
    height: 100%;
}

#content .areaDesign .content .areaHeader{

padding: 20px 10px;
}

#content .areaDesign .content .areaHeader .sidebarMenu {
    text-align: center;
    min-height: 105px;
    /* margin-left: 112px; */
}

#content .areaDesign .content .areaHeader .sidebarMenu .menu {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 10px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu:last-child{
    margin-right: 0;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu{
    display: flex;
    align-items: center;
    background: #F2F2F2;
    padding: 8px 10px 8px 9px;
    box-shadow: 4px 4px 5.5px -3px rgba(0,0,0,0.3);
    border-radius: 5px;
    border: 0;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu[disabled] .name{
    color: #ccc;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu[disabled]:hover{
    background: #F2F2F2;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu:focus{
    outline: 0;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu #redo-obj{
    transform: translateY(-3px);
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu:hover{
    background: #FFD702;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .name {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Noto Sans JP', sans-serif;
    color: #040000;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon {
    position: relative;
    width: 16px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon.icon-rm-img{
    width: 23px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon.icon-save{
    width: 18px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-undo{
    width: 21px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-redo {
    margin: 0 0 0 8px;
    width: 21px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon::after{
    position: absolute;
    content: '';
    width: 16px;
    height: 20px;
    left: 0;
}

#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-rm-img::after{
    background: url(/assets/images/common/icon-remove-img.png) no-repeat;
    background-size: 100%;
    width: 23px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-angle::after{
    background: url(/assets/images/common/icon-angle.png) no-repeat;
    background-size: 100%;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-trash::after{
    background: url(/assets/images/common/icon-trash.png) no-repeat;
    background-size: 100%;
}

#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-undo::after{
    background: url(/assets/images/common/icon-arrow-undo-sharp.svg) no-repeat;
    background-size: 100%;    
    width: 21px;
    height: 18px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu[disabled] .icon.icon-undo::after{
    background: url(/assets/images/common/icon-arrow-undo-sharp-disabled.svg) no-repeat;
    background-size: 100%;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-redo::after{
    background: url(/assets/images/common/icon-arrow-undo-sharp2.svg) no-repeat;
    background-size: 100%;
    width: 21px;
    height: 18px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu[disabled] .icon.icon-redo::after{
    background: url(/assets/images/common/icon-arrow-undo-sharp2-disabled.svg) no-repeat;
    background-size: 100%;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-trash-2::after{
    background: url(/assets/images/common/icon-trash-2.png) no-repeat;
    background-size: 100%;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-save::after{
    background: url(/assets/images/common/icon-save.png) no-repeat;
    background-size: 100%;
    width: 18px;
}
#content .areaDesign .content .areaHeader .sidebarMenu .menu .icon.icon-top-layer::after{
    background: url(/assets/images/common/icon-top-layer.svg) no-repeat;
    background-size: 100%;    
    width: 21px;
    height: 21px;
}
#content .areaDesign .sidebar-right{
    background: #F4F4F4;
    width: 335px;
}
#content .areaDesign .sidebar-right .areaHeader{
    background-color: #303030;
    padding: 3px 0 10px 20px;
}
#content .areaDesign .sidebar-right .areaHeader .img-title{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 7px;
}
#content .areaDesign .sidebar-right .areaHeader .title{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    color: #FFFFFF;
    letter-spacing: 0;
    transform: translateY(8px);
}
#content .areaDesign .sidebar-right .areaBody{
    
}
#content .areaDesign .sidebar-right .areaBody .areaTitle{
    padding: 20px 10px 0 10px;
}
#content .areaDesign .sidebar-right .areaBody .areaTitle .title{
    font-size: 16px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    border-bottom: 1px solid #ADADAD;
    padding-bottom: 2px;
    line-height: 1.5;
}

/* sidebar left */
.areaSidebarLeft {
    background-color: #FFD702;
    width: 150px;
    overflow-y: auto;
    min-height: 900px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.areaSidebarLeft .sidebar-footer{
    margin-top: 50px;
}

.areaSidebarLeft .sidebar-footer .menu-design{
    margin-bottom: 24px;
}
.areaSidebarLeft .sidebar-footer .menu-design .img-text{
    width: 76px;
    margin: 5px auto 0;
}
.areaSidebarLeft .sidebar-footer .menu-design .img-text img{
    margin-left: 5px;
}
.areaSidebarLeft .sidebar {
    padding: 22px 0;
}

.areaSidebarLeft ul li{
}
.areaSidebarLeft ul li:not(:first-child, :last-child){
    margin-bottom: 24px;
}
.areaSidebarLeft ul li.back{

}
.areaSidebarLeft ul li.back a{
    display: flex;
    align-items: center;
    padding: 5px 10px;
}
.areaSidebarLeft ul li.back a:hover{
    /* background-color: transparent; */
}
.areaSidebarLeft ul li.back a .img{
    width: 17px;
    height: 17px;
    margin-right: 10px;
}
.areaSidebarLeft ul li.back a .txt{
    margin-top: 0;
}
.areaSidebarLeft ul li a {
    max-width: 130px;
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 18px 0px;
}
.areaSidebarLeft ul li a:hover{
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 13px;
}
.areaSidebarLeft ul li.active a{
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 13px;
}
.areaSidebarLeft ul li a .img{
    width: 55px;
}

.areaSidebarLeft ul li a .txt{
    font-size: 16px;
    color: #040000;
    margin-top: 10px;
    font-weight: bold;
}
/* end sidebar left */


/* form title */
.areaForm{
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0 110px;
}
.areaForm .areaTitle{
    text-align: center;
    padding: 0 0 79px;
}
.areaForm .areaTitle .icon{
    display: block;
    width: 125px;
    margin: 0 auto;
}
.areaForm .areaTitle .icon:hover{
    cursor: unset;
}
.areaForm .areaTitle .title{
    font-size: 40px;
    font-weight: bold;
    color: #040000;
    margin-top: 39px;
    letter-spacing: 3px;
}

/* end form title*/

/* form input  */

.areaForm .areaInput:not(:first-child){
    margin-top: 50px;
}
.areaForm .areaInput.mt-0{
    margin-top: 0 !important;
}
.areaForm .areaInput.two{
    display: flex;
    justify-content: space-between;
}
.areaForm .areaInput.two .boxInput{
    width: 47.7%;
}
.areaForm .areaInput .boxInput{
    width: 100%;
}
.areaForm .areaInput .boxInput .title{
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: #040000;
    margin-bottom: 19px;
}
.areaForm .areaInput .boxInput .styleInput{
    border: 1px solid #ADADAD;
    padding: 20px 19px 19px;
    font-size: 20px;
    width: 100%;
    background-color: #F5F5F5;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 1.3px;
    font-weight: 500;
}
.areaForm .areaInput .boxInput .styleInput:focus{
    border: 1px solid #FFD702;
}
.areaForm .areaInput .boxInput .styleInput::placeholder{
    color: #ADADAD;
}
.areaForm .areaInput .boxInput .warpPass{
    position: relative;
}
.areaForm .areaInput .boxInput .warpPass .styleInput[type="password"] {
    height: 70px;
    font-size: 55px;
    padding: 5.5px 17px 21px;
    letter-spacing: -0.6px;
    line-height: 0;
}
.areaForm .areaInput .boxInput .warpPass .styleInput[type="password"]::placeholder{
    font-size: 20px;
    transform: translateY(-10px);
}
@-moz-document url-prefix() {
    .areaForm .areaInput .boxInput .warpPass .styleInput[type="password"] {
        height: 70px;
        font-size: 18px;
        padding: 13px 17px 16px;
        letter-spacing: -0.6px;
        line-height: 0;
    }
}
.areaForm .areaInput .boxInput .warpPass .styleInput.safari[type="password"] {
    font-size: 40px;
    padding: 3px 17px 11px;
}
.areaForm .areaInput .boxInput .warpPass .styleInput.safari[type="password"]::placeholder{
    transform: translateY(2px);
}
.areaForm .areaInput .boxInput .warpPass .eye.hide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    background: url(/assets/images/common/eye-slash-svgrepo-com.png) no-repeat;
    background-size: 100%;
    width: 31.25px;
    height: 25px;
}
.areaForm .areaInput .boxInput .warpPass .eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 21px;
    background: url(/assets/images/common/eye-svgrepo-com.png) no-repeat;
    background-size: 100%;
    width: 28.12px;
    height: 18.75px;
}

.areaBtn{
    margin-top: 70px;
}
.areaBtn.two{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.areaBtn .btn{
    width: 300px;
    margin: 0 auto;
    width: 300px;
    margin: 0 auto;
    display: block;
    font-size: 20px;
    border: none;
    padding: 21px 0 19px;
    border-radius: 35px;
    color: white;
    background: #303030;
    cursor: pointer;
    transition: all 0.16s;
    text-align: center;
    position: relative;
    top: 0;
    letter-spacing: 2px;
    font-weight: bold;
    /* box-shadow: 0px 7px 0px 0px #454545, 0px 0px 7px 0px rgba(120, 120, 120, 0.4); */
}
.areaBtn .btn.greyish{
    background: #ADADAD;
}
.areaBtn .btn::after{
    content: '';
    position: absolute;
    top: 51%;
    transform: translateY(-51%);
    right: 20px;
    width: 29px;
    height: 29px;
}
.areaBtn .btn.right::after{
    right: 20px;
    background: url(/assets/images/common/icon-awesome-arrow-alt-circle-right.png) no-repeat;
    background-size: 100%;
}

.areaBtn .btn.left::after{
    left: 20px;
    background: url(/assets/images/common/icon-awesome-arrow-alt-circle-left.png) no-repeat;
    background-size: 100%;
}
/* end form input  */

#content .areaAdminDesign .content{
    display: block;
    width: calc(100% - 150px);
    background-color: #FFFFFF;
    height: 100%;
    padding: 59px 50px 110px;
    margin: 0 auto;
}

/* FOOTER */

#footer {
    width: 100%;
}

#footer .copyRight{
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0;
    font-size: 14px;
    letter-spacing: 0.6px;
    padding: 15px 0 14px;
    background-color: #040000;
}
#footer .copyRight a{
    color: #FFFFFF;
}
#footer .copyRight a:hover{
    color: #FFD702;
}
.sp {
    display: none;
}



/* end input style */
/* PC ONLY */
@media (min-width: 769px) {
    /* .mainMenu .menu li a:hover{
        opacity: 1;
        background: #75D833;
    } */
    .hoverBtn {
        position: relative;
    }

    .hoverBtn:before,
    .hoverBtn:after {
        content: "";
        border-bottom: solid 2px #ccc;
        position: absolute;
        bottom: 0;
        width: 0;
    }
    .mainMenu .menu li > ul.subMenu li a:hover{
        background-color: #FFFFFF
    }
    .hoverBtn:before {
        left: 0;
    }

    .hoverBtn:after {
        right: 0;
    }

    .hoverBtn:hover:before,
    .hoverBtn:hover:after {
        width: 50%;
    }

    .hoverBtn:before,
    .hoverBtn:after {
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .headerBar .mainMenu {
        display: flex !important;
    }
}

.mainMenu.logged .menu li > ul.subMenu .sp{
    display: none;
}
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
}
.popup.hide{
    display: none;
}
.popup .wrapPopupContent {
    width: 548px;
    border: 5px solid #FFD702;
    border-radius: 10px;
    margin: 0 auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -49.4%);
    background: #FFFFFF;
    height: 178px;
    text-align: center;
    padding: 30px 10px 30px;
}
.popup .wrapPopupContent .close{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 5px;
    top: 5px;
}
.popup .wrapPopupContent .title {
    color: #040000;
    font-size: 20px;
    font-weight: 500;
}

.popup .wrapPopupContent .wrapBtn {

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0 0;
}

.popup .wrapPopupContent .wrapBtn .btn {
    width: 130px;
    background: #F2F2F2;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0;
    border-radius: 5px;
    box-shadow: 3px 3px 3.5px -2px rgba(0,0,0,0.3);
    margin-right: 41px;
    font-family: 'Noto Sans JP', sans-serif;
}
.popup .wrapPopupContent .wrapBtn .btn:last-child{
    margin-right: 0;
}
@media (min-width: 769px) {
    
    .popup .wrapPopupContent .wrapBtn .btn:hover{
        background: #FFD702;
    }
}
/* STYLE MOBILE */
@media (min-width: 1369px){
    #content .areaAdminDesign .content{
        /* width: 1218px; */
    }
}
@media (max-width: 950px) {
    .areaBtn.two .btn{
        width: 275px;
    }
}
@media (max-width: 900px) {
    #content .areaAdminDesign .content{
        padding: 59px 30px 110px;
    }
}
@media (max-width: 855px){
    body {
        width: 1368px;
        overflow-x: auto;
    }
}
/* @media (min-width: 769px) and (max-width: 1000px){ */
@media (min-width: 856px) and (max-width: 1000px){
    #content .areaDesign .content {
        width: calc(100% - (150px + 245px)); 
    }
    #content .areaDesign .sidebar-right{
        width: 245px;
    }
    #content .areaDesign .content .areaHeader .sidebarMenu .menu .btn-menu{
        padding: 9px 11px 9px 14px;
    }
    #content .areaDesign .content .areaHeader .sidebarMenu .menu .icon{
        margin-right: 0;
    }
    #content .areaDesign .content .areaHeader .sidebarMenu .menu .name{
        display: none;
    }
}
@media (min-width: 769px) and (max-width: 875px){
    .areaBtn.two .btn{
        width: 250px;
    }
}
@media (max-width: 768px){
    
    .areaBtn.two{
        width: 700px;
    }
}

/*IE FIX*/

@media (orientation: landscape) and (max-width:768px) {
    .headerBar .mainMenu {
        overflow-y: scroll;
        padding-bottom: 40px;
    }
}

@media all and (-ms-high-contrast: none) {}

/*IP5*/
@media not all and (min-resolution:.001dpcm){
    /* .areaForm .areaInput .boxInput .warpPass .styleInput[type="password"] {
        font-size: 40px;
        padding: 3px 17px 11px;
    }
    .areaForm .areaInput .boxInput .warpPass .styleInput[type="password"]::placeholder{
        transform: translateY(2px);
    } */
}
@media not all and (-webkit-min-device-pixel-ratio:0) { 
    
} 